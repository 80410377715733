<template>
  <section class="projects fade d-flex flex-column">
    <div class="edit-project-wrapper">
      <div class="edit-project__header mb-6">
        <div class="edit-project__title">Редактировать заказ</div>
        <v-btn
          class="edit-project__close"
          icon
          plain
          @click="closeEdit"
        >
          <v-icon
            color="black"
            size="20"
          >mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="project-info">
        <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          class="mt-8"
        >
          <div class="d-flex justify-space-between mb-4">
            <div class="col-item">
              <v-text-field
                v-model="project.title"
                label="Название заказа"
                class="rounded-lg"
                hide-details
                background-color="white"
                outlined
                required
                :rules="nameRules"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-space-between mb-4">

            <div class="col-item">
              <DatePicker
                placeholder="Начало заказа"
                :value="project.date_start"
                @changeDate="changeDateFirst"
              />
              <div class="ml-1">
                <DatePicker
                  placeholder="Окончание заказа"
                  :value="project.date_end"
                  @changeDate="changeDateSecond"
                />
              </div>
            </div>

            <div class="col-item">
              <Budget
                v-if="project.id"
                :budgetStr="String(project.budget)"
                @changeBudget="changeBudget"
              />
            </div>

            <div class="col-item">
              <v-select
                :items="status"
                v-model="project.status"
                label="Статус"
                outlined
                hide-details
                background-color="white"
                class="rounded-lg"
              ></v-select>
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <v-textarea
              outlined
              v-model="project.description"
              name="input-7-4"
              hide-details
              class="rounded-lg"
              background-color="white"
              no-resize
              height="135"
              label="Описание"
              required
              :rules="nameRules"
            ></v-textarea>
          </div>
        </v-form>
      </div>
    </div>

    <SpecialistTime
      v-if="loading"
      edit
      :team="project.resources"
      @rates="changeRates"
    />
    <v-btn
      class="white--text btn-edit radius-root"
      color="black"
      width="210"
      height="52"
      :disabled="!valid"
      @click="submit()"
    >
      Сохранить
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { statuses } from '@/core/constants/statuses';
import notification from '@/mixins/notification';
import SpecialistTime from './components/SpecialistTime.vue';
import Budget from '@/components/Inputs/Budget.vue';
import DatePicker from '@/components/UI/DatePicker.vue';

export default {
  name: 'EditProjectTask',
  mixins: [notification],
  components: {
    SpecialistTime,
    Budget,
    DatePicker,
  },
  data() {
    return {
      valid: false,
      loading: false,
      project: {
        title: '',
        description: '',
        status: '',
        manager: '',
        code: '',
        budget: '',
        date_start: '',
        date_end: '',
        resources: [],
      },
      status: statuses,
    };
  },

  computed: {
    ...mapState('staff', ['managers', 'specialists']),
  },
  methods: {
    ...mapActions('projects', ['getProjectTask', 'editProjectTask']),
    checkValid() {
      const isValid = this.$refs.form.validate();
      return new Promise((resolve) => {
        this.isDatesPeriodValid(this.project.date_start, this.project.date_end, () => {
          this.project.date_end = '';
          this.showErrorNotification('Дата окончания заказа введена некорректно');
          resolve(false);
        }, () => {
          resolve(isValid);
        });
      });
    },

    async submit() {
      if (await this.checkValid()) {
        try {
          await this.editProjectTask({
            payload: {
              ...this.project,
              resources: this.project.resources.map((el) => ({
                ...el,
                project_specialist: el.project_specialist.id,
                codename: el.project_specialist.resource_kind.codename,
              })),
            },
            pid: this.$route.params.pid,
          });
          this.$router.back();
        } catch (error) {
          return error;
        }
      }
    },

    changeDateFirst(val) {
      this.project.date_start = val;
    },

    changeDateSecond(val) {
      this.project.date_end = val;
    },

    changeBudget(val) {
      this.project.budget = val;
    },

    closeEdit() {
      this.$emit('closeEdit');
      this.$router.back();
    },

    changeRates(data) {
      this.project.resources = data;
    },
  },
  async created() {
    const { data } = await this.getProjectTask(this.$route.params);
    this.project = JSON.parse(JSON.stringify(data));
    this.loading = true;
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-text-field fieldset {
  color: #d6d6d6 !important;
}

.btn-edit {
  align-self: center;
}

.edit-project-wrapper {
  margin-bottom: 40px;
}

.edit-project__header {
  display: flex;
  align-items: center;
}

.edit-project__title {
  margin-left: 32px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.edit-project__close {
  margin-left: auto;
  margin-right: 10px;
}

.project-info {
  border: 1px solid #f1f1f1;
  border-radius: 32px;
  height: 359px;
  background: #f9f9f9;
  & > * {
    padding: 0 32px;
  }
}

.col-item {
  display: flex;
  width: 33%;
}

.modal__sub-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staff-list__item {
  width: 49%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .v-input {
    max-width: 190px;
  }
}
</style>
